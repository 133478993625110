/********** Template CSS **********/
:root {
  --primary: #f68642;
  --secondary: #454545;
  --light: #f6f4f9;
  --dark: #04000b;
}

/*** Spinner ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-out, visibility 0s linear 0.5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity 0.5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 45px;
  bottom: 45px;
  z-index: 99;
}

/*** Heading ***/
h1,
h2,
.fw-bold {
  font-weight: 700 !important;
}

h3,
h4,
.fw-semi-bold {
  font-weight: 600 !important;
}

h5,
h6,
.fw-medium {
  font-weight: 500 !important;
}

/*** Button ***/
.btn {
  font-weight: 500;
  transition: 0.5s;
}

.btn:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  border-radius: 50px;
}

/*** Navbar ***/
.navbar .dropdown-toggle::after {
  border: none;
  content: "\f067";
  font-family: "Font Awesome 5 Free";
  font-size: 10px;
  font-weight: bold;
  vertical-align: middle;
  margin-left: 8px;
}

.navbar-light .navbar-nav .nav-link {
  position: relative;
  margin-right: 25px;
  padding: 35px 0;
  font-family: "Jost", sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: var(--light) !important;
  outline: none;
  transition: 0.5s;
}

.sticky-top.navbar-light .navbar-nav .nav-link {
  padding: 20px 0;
  color: var(--dark) !important;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link.active {
  color: var(--secondary) !important;
}

.navbar-light .navbar-brand h1 {
  color: #ffffff;
}

.navbar-light .navbar-brand img {
  max-height: 60px;
  transition: 0.5s;
}

.sticky-top.navbar-light .navbar-brand img {
  max-height: 45px;
}

@media (max-width: 991.98px) {
  .sticky-top.navbar-light {
    position: relative;
    background: #ffffff;
  }

  .navbar-light .navbar-collapse {
    margin-top: 15px;
    border-top: 1px solid #dddddd;
  }

  .navbar-light .navbar-nav .nav-link,
  .sticky-top.navbar-light .navbar-nav .nav-link {
    padding: 10px 0;
    margin-left: 0;
    color: var(--dark) !important;
  }

  .navbar-light .navbar-brand h1 {
    color: var(--primary);
  }

  .navbar-light .navbar-brand img {
    max-height: 45px;
  }
}

@media (min-width: 992px) {
  .navbar-light {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    border-bottom: 1px solid rgba(256, 256, 256, 0.1);
    z-index: 999;
  }

  .sticky-top.navbar-light {
    position: fixed;
    background: #ffffff;
  }

  .sticky-top.navbar-light .navbar-brand h1 {
    color: var(--primary);
  }

  .navbar-light .navbar-nav .nav-item .dropdown-menu {
    display: block;
    border: none;
    margin-top: 0;
    top: 150%;
    opacity: 0;
    visibility: hidden;
    transition: 0.5s;
  }

  .navbar-light .navbar-nav .nav-item:hover .dropdown-menu {
    top: 100%;
    visibility: visible;
    transition: 0.5s;
    opacity: 1;
  }

  .navbar-light .btn {
    color: var(--dark);
    background: #ffffff;
  }

  .sticky-top.navbar-light .btn {
    color: var(--dark);
    background: var(--secondary);
  }
}

/*** Hero Header ***/
.hero-header {
  /* margin-bottom: 6rem; */
  padding: 18rem 0;

  /* Nuvole in fondo alla sezione del header: */
  background-position: left 0px top 0px, right 0px top 0px, left 0px bottom 0px,
    right 0px bottom 0px, center center, center bottom;
  background-repeat: no-repeat;
}

@media (max-width: 991.98px) {
  .hero-header {
    padding: 6rem 0 9rem 0;
  }
}

/*** Section Title ***/
.section-title {
  position: relative;
  display: flex;
  align-items: center;
  font-weight: 500;
  text-transform: uppercase;
}

.section-title span:first-child,
.section-title span:last-child {
  position: relative;
  display: inline-block;
  margin-right: 30px;
  width: 30px;
  height: 2px;
}

.section-title span:last-child {
  margin-right: 0;
  margin-left: 30px;
}

.section-title span:first-child::after,
.section-title span:last-child::after {
  position: absolute;
  content: "";
  width: 15px;
  height: 2px;
  top: 0;
  right: -20px;
}

.section-title span:last-child::after {
  right: auto;
  left: -20px;
}

.section-title.text-primary span:first-child,
.section-title.text-primary span:last-child,
.section-title.text-primary span:first-child::after,
.section-title.text-primary span:last-child::after {
  background: var(--primary);
}

.section-title.text-secondary span:first-child,
.section-title.text-secondary span:last-child,
.section-title.text-secondary span:first-child::after,
.section-title.text-secondary span:last-child::after {
  background: var(--secondary);
}

.section-title.text-white span:first-child,
.section-title.text-white span:last-child,
.section-title.text-white span:first-child::after,
.section-title.text-white span:last-child::after {
  background: #ffffff;
}

/*** Feature ***/
.feature-item {
  transition: 0.5s;
}

.feature-item:hover {
  margin-top: -15px;
}

/*** About ***/
.progress {
  height: 5px;
}

.progress .progress-bar {
  width: 0px;
  transition: 3s;
}

/*** Fact ***/
.fact {
  /*margin: 6rem 0;*/
  background-position: left 0px top 0px, right 0px top 0px, left 0px bottom 0px,
    right 0px bottom 0px, center center;
  background-repeat: no-repeat;
}

/*** Service ***/
.service-item {
  position: relative;
  padding: 45px 30px;
  background: var(--light);
  overflow: hidden;
  transition: 0.5s;
}

.service-item:hover {
  margin-top: -15px;
  padding-bottom: 60px;
  background: var(--primary);
}

.service-item .service-icon {
  margin: 0 auto 20px auto;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--light);
  background-size: contain;
  transition: 0.5s;
}

.service-item:hover .service-icon {
  color: var(--dark);
  background-size: contain;
}

.service-item h5,
.service-item p {
  transition: 0.5s;
}

.service-item:hover h5,
.service-item:hover p {
  color: #ffffff;
}

.service-item a.btn {
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
  color: var(--primary);
  background: #ffffff;
  border-radius: 40px 40px 0 0;
  transition: 0.5s;
  z-index: 1;
}

.service-item a.btn:hover {
  color: var(--dark);
  background: var(--secondary);
}

.service-item:hover a.btn {
  bottom: 0;
}

/*** Project Portfolio ***/
#portfolio-flters li {
  display: inline-block;
  font-weight: 500;
  color: var(--dark);
  cursor: pointer;
  transition: 0.5s;
  border-bottom: 2px solid transparent;
}

#portfolio-flters li:hover,
#portfolio-flters li.active {
  color: var(--primary);
  border-color: var(--primary);
}

.portfolio-item img {
  transition: 0.5s;
}

.portfolio-item:hover img {
  transform: scale(1.1);
}

.portfolio-item .portfolio-overlay {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #f68642c4;
  transition: 0.5s;
  opacity: 0;
}

.portfolio-item:hover .portfolio-overlay {
  opacity: 1;
}

/*** Newsletter ***/
.newsletter {
  margin: 6rem 0 0 0;
  background-position: left 0px top 0px, right 0px top 0px, left 0px bottom 0px,
    right 0px bottom 0px, center center;
  background-repeat: no-repeat;
}

/*** Testimonial ***/
.testimonial-carousel .testimonial-item {
  padding: 0 30px 30px 30px;
}

.testimonial-carousel .owl-nav {
  display: flex;
  justify-content: center;
}

.testimonial-carousel .owl-nav .owl-prev,
.testimonial-carousel .owl-nav .owl-next {
  margin: 0 12px;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary);
  background: var(--light);
  border-radius: 60px;
  font-size: 22px;
  transition: 0.5s;
}

.testimonial-carousel .owl-nav .owl-prev:hover,
.testimonial-carousel .owl-nav .owl-next:hover {
  color: #ffffff;
  background: var(--primary);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

/*** Team ***/
.team-item .btn {
  color: var(--primary);
  background: #ffffff;
}

.team-item .btn:hover {
  color: #ffffff;
  background: var(--primary);
}
/*** Footer ***/
.footer {
  margin-top: 6rem;
  padding-top: 5rem;
  background-position: center top, center center;
  background-repeat: no-repeat;
}

.footer .btn.btn-social {
  margin-right: 5px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--light);
  border: 1px solid rgba(256, 256, 256, 0.1);
  border-radius: 40px;
  transition: 0.3s;
}

.footer .btn.btn-social:hover {
  color: var(--primary);
}

.footer .btn.btn-link {
  display: block;
  margin-bottom: 10px;
  padding: 0;
  text-align: left;
  color: var(--light);
  font-weight: normal;
  transition: 0.3s;
}

.footer .btn.btn-link::before {
  position: relative;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 10px;
}

.footer .btn.btn-link:hover {
  letter-spacing: 1px;
  box-shadow: none;
  color: var(--secondary);
}

.footer .copyright {
  padding: 25px 0;
  font-size: 14px;
  border-top: 1px solid rgba(256, 256, 256, 0.1);
}

.footer .copyright a {
  color: var(--light);
}

.footer .footer-menu a {
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.footer .footer-menu a:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: none;
}

.footer .copyright a:hover,
.footer .footer-menu a:hover {
  color: var(--secondary);
}

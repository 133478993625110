@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";




.orange{
  color: #f68642;
}

@media (min-width: 640px) {
  header div.text-white.flex-grow.z-20 img.mainLogo {
    width: 8.33333%; /* 1/12 of the container width on medium screens and above */
  }
}

@media (min-width: 1024px) {
  img.mainLogo {
    width: 8.33333%; /* 1/12 of the container width on large screens and above */
  }
}